@import 'variables';

.white {
  color: $white !important;
}

.turtle-green {
  color: $turtle-green !important;
}

.lime-green {
  color: $lime-green !important;
}

.milk-white {
  color: $milk-white !important;
}

.spicy-mix {
  color: $spicy-mix !important;
}

.mantle {
  color: $mantle !important;
}

.bermuda-grey {
  color: $bermuda-grey !important;
}

.dingley {
  color: $dingley !important;
}

.santa-fe {
  color: $santa-fe !important;
}

.barley-corn {
  color: $barley-corn !important;
}

.midnight-blue {
  color: $midnight-blue !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-turtle-green {
  background-color: $turtle-green !important;
}

.bg-lime-green {
  background-color: $lime-green !important;
}

.bg-milk-white {
  background-color: $milk-white !important;
}

.bg-spicy-mix {
  background-color: $spicy-mix !important;
}

.bg-mantle {
  background-color: $mantle !important;
}

.bg-bermuda-grey {
  background-color: $bermuda-grey !important;
}

.bg-bermuda-grey-90 {
  background-color: rgba($bermuda-grey, 0.9) !important;
}

.bg-dingley {
  background-color: $dingley !important;
}

.bg-dingley-90 {
  background-color: rgba($dingley, 0.9) !important;
}

.bg-santa-fe {
  background-color: $santa-fe !important;
}

.bg-santa-fe-90 {
  background-color: rgba($santa-fe, 0.90) !important;
}

.bg-barley-corn {
  background-color: $barley-corn !important;
}

.bg-barley-corn-90 {
  background-color: rgba($barley-corn, 0.90) !important;
}