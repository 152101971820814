@import 'variables';

.nav-item {
  width: 100%;
  transition: height 0.5s ease;
}

.nav-link {
  color: $milk-white;
  padding: 0.5rem;
  background-color: transparent;
  width: 50%;
  height: 75%;
  text-align: center;
  transition: all 0.5s ease;
  font-weight: $font-weight-bold;
}

.nav-link:hover {
  color: $milk-white;
  background-color: #b46257;
  text-decoration: none;
}

.nav-link:focus {
   color: $milk-white;
}

.nav-link-icon {
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease;
  font-size: 1.5rem;
}

.nav-link:hover .nav-link-icon {
  opacity: 1;
}

.jump-nav {
  width: 100%;
  position: relative;
  top: unset;
  transition: width 0.5s ease;
  z-index: 10;
}

.jump-nav.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  flex-direction: row !important;
}

.jump-nav.sticky .nav-item {
  height: 86px;
  width: 25%;
}

.jump-nav.sticky .nav-link {
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.scrolled #main {
  margin-top: 0;
}

.scrolled #developer {
  padding-top: 0;
}

@media screen and (min-width: 576px) {
  .nav-link:hover {
    height: 100%;
    width: 100%;
  }

  .nav-link-icon {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .scrolled #main {
    margin-top: -43px;
  }

  .scrolled #developer {
    padding-top: 86px;
  }

  .nav-item {
    width: 25%;
    height: 160px;
  }

  .nav-link {
    width: 100%;
    height: 50%;
  }

  .jump-nav {
    width: 440px;
  }
}