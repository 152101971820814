$white: #fff;
$turtle-green: #2b381a;
$milk-white: #d9dace;
$spicy-mix: #88654c;
$spicy-mix-rgb: 136, 101, 76;
$mantle: #9daf95;
$bermuda-grey: #6e91a0;
$bermuda-grey-rgb: 110, 145, 160;
$dingley: #607946;
$dingley-rgb: 96, 121, 70;
$santa-fe: #b46257;
$santa-fe-rgb: 180, 98, 87;
$barley-corn: #b89259;
$barley-corn-rgb: 184, 146, 89;
$midnight-blue: #0c0c36;
$lime-green: #32CD32;
$lime-green-rgb: 50, 205, 50;
$font-family-body: 'Cairo', sans-serif;
$font-family-code: 'Syne Mono', monospace !important;
$font-size-huge: 4.209rem;
$font-size-large: 3.157rem;
$font-size-tall: 2.369rem;
$font-size-medium: 1.777rem;
$font-size-regular: 1.333rem;
$font-weight-bold: 600;
$font-weight-semibold: 500;
$font-weight-medium: 400;
$font-weight-regular: 300;
$line-height-body: 1.75rem;