@use '~bootstrap/scss/bootstrap';
// @import 'variables';
@use 'custom';
@use 'styles/typography';
@import 'styles/colors';
@import 'styles/layout';
@import 'styles/nav';

html {
  font-size: 100%;
  /* 16px */
}

body {
  font-family: $font-family-body;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;
  color: $milk-white;
  background-color: $dingley;
  background-image: url("assets/img/cdetmer-urban-trail-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &.work {
    background-image: url("assets/img/cdetmer-skyway-bridge-bg.jpg");
  }

  &.resume {
    background-image: url("assets/img/cdetmer-ribble-valley-bg.jpg");
  }
}

/*** Links ***/
a, .link {
  color: $milk-white;

  &:hover {
    color: $turtle-green;
    text-decoration: underline;
  }

  &-external {
    display: inline-block;
  }
}

/*** Buttons ***/
.btn {
  &-text {
    border: none;
    background-color: transparent;
    color: inherit;

    &:hover {
      color: $turtle-green;
    }
  }

  &-link {
    padding-left: 0;
  }

  &-play {
    color: $milk-white;
    font-family: $font-family-body;
    margin-left: -0.75rem;

    &:hover {
      background-color: $milk-white;
      color: $midnight-blue;

      .bi {
        margin-left: 0.5rem;
      }
    }
  }

  // &-email {
  //   padding: 0;
  //   font-size: $font-size-regular;
  //   line-height: $line-height-body;
  // }
}

/*** Lists ***/
.list-bulleted {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin-bottom: 1rem;
}

dt {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.25rem;
}

dt small {
  font-size: 0.9rem;
}

dd {
  margin: 1rem 0 2rem;
}

dd ul {
  list-style-type: disc;
  padding-left: 1.25rem;
}


.mh-480 {
  min-height: 480px;
}

.code-block {
  font-family: $font-family-code;
  color: $milk-white;
  background-color: $turtle-green;
}

.diplay-email::after {
  display: block;
  content: "chrisdetmer@gmail.com";
}

.start-button {
  background-color: $milk-white;
  color: $midnight-blue;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transform: translateY(200%);
  transition: transform 0.5s ease;
}

.start-button:hover {
  text-decoration: none;
  color: $milk-white;
  background-color: $midnight-blue;
}

.scrolled .start-button {
  transform: translateY(0%);
}

/*** Images ***/
.img-box {
  width: 100%;
  aspect-ratio: 3/4;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 480px) {
  .img-box {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .img-box {
    width: 100%;
  }
}

/** Videos **/
.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-player-wrapper {
  padding: 54.69% 0 0 0;
  position: relative;
}

/*** Bootstrap Overrides ***/

.card {
  position: relative;
  border-radius: 0;
  background-color: transparent;
  border: none;
}

.card-img-top {
  position: relative;
  z-index: 0;
  border-radius: 0;
}

.modal-header {
  border-bottom: none;
}

.modal-header .btn-close {
  color: $milk-white;
  background-image :url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>');
  font-size: 2rem;
}

.modal-content {
  background-color: $midnight-blue;
}