#main {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s linear;
  position: relative;
}

.scrolled #main {
  background-color: rgba(0, 0, 0, 0.7);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($midnight-blue, 0.9);
  z-index: 100;
}

.vh-75 {
  height: 75vh;
}

.vh-50 {
  height: 50vh;
}

.vh-25 {
  height: 25vh;
}

.vw-90 {
  width: 90vw;
}

.vw-75 {
  width: 75vw;
}

.vw-50 {
  width: 50vw;
}

@media screen and (min-width: 768px) {
  .vh-md-100 {
    height: 100vh !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .vw-md-90 {
    width: 90vw !important;
  }

  .vw-md-75 {
    width: 75vw !important;
  }
}

@media screen and (min-width: 992px) {
  .vw-lg-90 {
    width: 90vw !important;
  }

  .vw-lg-75 {
    width: 75vw !important;
  }

  .vw-lg-90 {
    width: 90vw !important;
  }
}

@media screen and (min-width: 1200px) {
  .vw-xl-75 {
    width: 75vw !important;
  }
}