@import 'variables';

p {
  margin-bottom: 1rem;
  font-weight: $font-weight-regular;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: $font-weight-semibold;
  line-height: 1.3;
}

h1,
.h1 {
  margin-top: 0;
  font-size: $font-size-large;
}

.interior {

  h1,
  .h1 {
    @media screen and (max-width: 480px) {
      font-size: $font-size-medium;
    }
  }
}

h2,
.h2 {
  font-size: $font-size-tall;
}

h3,
.h3 {
  font-size: $font-size-medium;
}

h4,
.h4 {
  font-size: $font-size-regular;
}

h5,
.h5 {
  font-size: 1rem;
}

ul,
.list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

small,
.small {
  font-size: 0.75rem;
}

strong,
.strong {
  font-weight: $font-weight-bold;
}

.font {
  &-heading {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: $font-weight-semibold;
  }

  &-code {
    font-family: $font-family-code;
  }

  &-size {
    &-huge {
      font-size: $font-size-large;
    }

    &-large {
      font-size: $font-size-tall;
    }

    &-tall {
      font-size: $font-size-medium;
    }

    &-medium {
      font-size: $font-size-regular;
    }

    &-regular {
      font-size: 1rem;
    }
  }

  &-weight {
    &-bold {
      font-weight: $font-weight-bold;
    }

    &-semibold {
      font-weight: $font-weight-semibold;
    }

    &-medium {
      font-weight: $font-weight-medium;
    }

    &-regular {
      font-weight: $font-weight-regular;
    }
  }
}

@media screen and (min-width: 576px) {

  h1,
  .h1 {
    font-size: $font-size-huge;
  }

  h2,
  .h2 {
    font-size: $font-size-large;
  }

  h3,
  .h3 {
    font-size: $font-size-tall;
  }

  h4,
  .h4 {
    font-size: $font-size-medium;
  }

  h5,
  .h5 {
    font-size: $font-size-regular;
  }

  .font {
    &-size {
      &-huge {
        font-size: $font-size-huge;
      }

      &-large {
        font-size: $font-size-large;
      }

      &-tall {
        font-size: $font-size-tall;
      }

      &-medium {
        font-size: $font-size-medium;
      }

      &-regular {
        font-size: $font-size-regular;
      }
    }
  }
}